import React from 'react';
import Modal, { ModalTransition, ModalFooter, ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import If from './If';
import { observer } from 'mobx-react';
import Button from '@atlaskit/button/new';


const ConfirmationModal = observer(({ onClose, onConfirm, loading, text }) => {
    return (
        <>
            <ModalTransition>
                <Modal
                    onClose={onClose}
                    scrollBehavior="inside"
                    height={180}
                    width={800}
                >
                    <ModalHeader>
                        <ModalTitle>Confirmation</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <span>{text}</span>
                    </ModalBody>
                    <ModalFooter>
                        <If condition={loading}>
                            <div className="flex justify-center">
                                <Spinner />
                            </div>
                        </If>
                        <If condition={!loading}>
                            <Button appearance="subtle" onClick={onClose} isDisabled={loading}>
                                Cancel
                            </Button>
                            <Button appearance="primary" onClick={onConfirm} isDisabled={loading}>
                                Unlink
                            </Button>
                        </If>
                    </ModalFooter>
                </Modal>
            </ModalTransition>
        </>

    );
});

export default ConfirmationModal;