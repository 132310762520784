import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalTransition, ModalFooter, ModalBody } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Checkbox } from '@atlaskit/checkbox';
import PropTypes from 'prop-types';
import Spinner from '@atlaskit/spinner';
import Toggle from '@atlaskit/toggle';
import { TestBadge } from '../service/tableService';
import If from './If';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import { useStore } from '../store';
import Request from '../service/request';

const AllProjectsRun = observer(
  ({
    showModal, closeModal, testomatioURL, testMap, tests,
  }) => {
    const [selectedTests, setSelectedTests] = useState(testMap);
    const [loading, setLoading] = useState(false);
    const [runId, setRunId] = useState(undefined);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const { jira } = useStore();
    const [disabledButton, setDisabledButton] = useState(false);
    const [projects, setProjects] = useState(jira.projects);
    const [noTests, setNoTests] = useState(false);
    const [isAutomated, setIsAutomated] = useState(false);
    const [manualTests, setManualTests] = useState([]);
    const [autoManualTests, setAutoManualTests] = useState([]);
    const [allSelect, setAllSelect] = useState(false);

    useEffect(() => {
      const pros = tests.filter(test => !!selectedTests[test.id]).map(i => i.project);
      const newProjects = jira.projects.filter(p => {
        return [...new Set(pros)].some(pro => {
          return pro === p.slug;
        });
      });
      setProjects(newProjects);
      // eslint-disable-next-line
    }, [selectedTests, jira.projects]);

    const checkboxCallback = (e) => {
      if (!selectedTests[e.target.value]) {
        selectedTests[e.target.value] = true;
        setSelectedTests({ ...selectedTests });
      } else {
        selectedTests[e.target.value] = false;
        setSelectedTests({ ...selectedTests });
      }
      const testIds = Object.keys(selectedTests).filter(test => !!selectedTests[test]);
      const falseTests = Object.keys(selectedTests).filter(test => !selectedTests[test]);
      if (testIds.length === 0) {
        setDisabledButton(true);
        setAllSelect(false);
      } else {
        setDisabledButton(false);
      }

      if (falseTests.length > 0) {
        setAllSelect(false);
      } else {
        setAllSelect(true);
      }
    };

    useEffect(() => {
      setLoading(true);
      const manual = [];
      const auto = [];
      tests.forEach(test => {
        if (test.type === 'manual') manual.push(test);
        if (test.type === 'automated') auto.push(test);
      });
      setManualTests(manual);
      setAutoManualTests([...manual, ...auto]);
      setLoading(false);
      // eslint-disable-next-line
    }, []);

    const createRun = async () => {
      setLoading(true);
      const testIds = Object.keys(selectedTests).filter(test => !!selectedTests[test]);
      const newSet = new Set(testIds);
      const finalTests = isAutomated ? testIds : manualTests.filter(test => newSet.has(test.id)).map(item => item.id);
      const data = jira.runDetails;
      const request = new Request(jira.testomatioToken);
      try {
        projects.forEach(async p => {
          const env = data[p.slug].selectedEnvironments.length === 1
            ? data[p.slug].selectedEnvironments[0].map((item) => item.value).join(',')
            : undefined;
          const envs = data[p.slug].selectedEnvironments.length > 1
            ? data[p.slug].selectedEnvironments.map((environment) => {
              return environment.map((item) => item.value).join(',');
            })
            : undefined;

          const body = {
            type: 'runs',
            attributes: {
              automated: false,
              multienv: !!envs,
              'test-ids': finalTests,
              'assigned-to': data[p.slug].userId || undefined,
              title: data[p.slug].title || undefined,
              env,
              envs,
            },
            jira_id: jira.jiraId,
          };
          try {
            const response = await request.send(`/api/${p.slug}/runs`, {
              method: 'POST',
              body: JSON.stringify({ data: body }),
            });
            if (response && response.data.id) {
              jira.toast = {
                type: 'success',
                message: 'Manual Run was created',
              };
              setRunId(response.id);
            }
          } catch (e) {
            jira.toast = {
              type: 'error',
              message: `${p.slug}: ${e.message}`,
            };
          }
        });
      } catch (e) {
        console.log(e.message);
      } finally {
        jira.runDetails = null;
        setTimeout(() => {
          setLoading(false);
          closeModal(true);
        }, 1000);
      }
    };

    useEffect(() => {
      if (!!isAutomated && autoManualTests.length === 0) {
        setNoTests(true);
      } else if (!isAutomated && manualTests.length === 0) {
        setNoTests(true);
      } else {
        setNoTests(false);
      }
      // eslint-disable-next-line
    }, [isAutomated, autoManualTests, manualTests]);

    const selectAll = useCallback(() => {
      const testIds = Object.keys(selectedTests);
      if (!allSelect) {
        testIds.forEach(item => {
          selectedTests[item] = true;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(true);
        setDisabledButton(false);
      } else {
        testIds.forEach(item => {
          selectedTests[item] = false;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(false);
        setDisabledButton(true);
      }
    }, [selectedTests, allSelect]);

    const CustomFooter = () => {
      return (
        <ModalFooter style={{ padding: 12, justifyContent: 'space-between' }}>
          <If condition={runId === undefined}>
            <Button appearance="link" onClick={() => selectAll()} disabled={noTests}>
              Select all
            </Button>
            <div className='flex space-x-2'>
              <Button
                size="small"
                appearance="link"
                spacing="compact"
                className="ml-2"
                onClick={() => {
                  setShowAdvanced(!showAdvanced);
                }}
              >
                {showAdvanced ? 'Close' : 'Advanced'}
                {' '}
                settings
              </Button>
              <Button
                size="small"
                appearance="primary"
                spacing="compact"
                className="mx-2"
                onClick={() => createRun()}
                isLoading={loading}
                isDisabled={disabledButton}
              >
                Create Run
              </Button>
              <Button appearance="default" spacing="compact" size="small" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </If>
        </ModalFooter>
      );
    };

    return (
      <ModalTransition>
        <If condition={showModal}>
          <Modal
            onClose={closeModal}
            scrollBehavior="inside-wide"
            height={600}
            width="x-large"
          >
            <ModalBody>
              <div className="flex justify-between">
                <h2 className="text-base font-bold">Select tests to run</h2>
                <div className="flex items-center space-x-2">
                  <small className="text-sm">
                    Run Automated as Manual
                  </small>
                  <Toggle
                    id="toggle-tooltip"
                    onChange={() => setIsAutomated(prev => !prev)}
                  />
                </div>
              </div>
              {projects.map((p) => {
                return (
                  <CustomOption
                    project={p.title}
                    slug={p.slug}
                    showAdvanced={showAdvanced}
                    runId={runId}
                    tests={selectedTests}
                  />
                );
              })}
              <If condition={runId !== undefined}>
                <div className="flex flex-col justify-center mt-20 items-center">
                  <div>
                    <h2 className="text-xl">
                      Run created successfully
                      {' '}
                      <PremiumIcon size="small" />
                    </h2>
                  </div>
                  <a
                    href={`${testomatioURL}/runs/${runId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 font-bold border-none focus: outline-none mt-2"
                  >
                    <Button
                      iconBefore={
                        (iconProps) => <ShortcutIcon {...iconProps} label="Star icon" size="small" />
                      }
                      appearance="primary"
                    >
                      Open in Testomatio
                    </Button>
                  </a>
                </div>
              </If>
              <If condition={runId === undefined && !showAdvanced}>
                <div className="h-full pb-8">
                  {!loading && !!isAutomated && autoManualTests.map((test) => {
                    return (
                      <Checkbox
                        value={test.id}
                        label={(
                          <div>
                            <b>
                              {test.projectName}
                              {': '}
                            </b>
                            {test.title}
                            <span className="ml-2">
                              <TestBadge type={test.type} />
                            </span>
                          </div>
                        )}
                        isChecked={selectedTests[test.id]}
                        onChange={checkboxCallback}
                        key={test.id}
                      />
                    );
                  })}
                  {!loading && !isAutomated && manualTests.map((test) => {
                    return (
                      <Checkbox
                        value={test.id}
                        label={(
                          <div>
                            <b>
                              {test.projectName}
                              {': '}
                            </b>
                            {test.title}
                            <span className="ml-2">
                              <TestBadge type={test.type} />
                            </span>
                          </div>
                        )}
                        isChecked={selectedTests[test.id]}
                        onChange={checkboxCallback}
                        key={test.id}
                      />
                    );
                  })}
                  {!!loading && !noTests && (
                    <div className="flex justify-center mt-32">
                      <Spinner size="large" />
                    </div>
                  )}
                  {!loading && !!noTests && (
                    <div className="centered">
                      No tests for Run
                    </div>
                  )}
                </div>
              </If>
            </ModalBody>
            <CustomFooter />
          </Modal>
        </If>
      </ModalTransition>
    );
  },
);

const CustomOption = observer(({
  project, slug, showAdvanced, runId,
}) => {
  const [environments, setEnvironments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedEnvironments, setSelectedEnvironments] = useState([]);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [title, setTitle] = useState('');
  const [userId, setUserId] = useState(undefined);
  const { jira } = useStore();

  const fetchedEnvironments = useTestomatioFetch(
    `/api/projects/${slug}`,
    {
      method: 'GET',
    },
  );

  useEffect(() => {
    if (!fetchedEnvironments.response) return;
    const newEnvironments = fetchedEnvironments.response?.attributes?.environments || [];
    const options = newEnvironments.map((env) => ({
      label: env,
      value: env,
    }));
    setEnvironments(options);
  }, [fetchedEnvironments.response]);

  const fetchedUsers = useTestomatioFetch(`/api/${slug}/users`, {
    method: 'GET',
    asHash: true,
  });

  useEffect(() => {
    if (!fetchedUsers.response) return;
    const allUsers = fetchedUsers.response;
    const options = allUsers.map((userData) => {
      return { label: userData.email, value: userData.id };
    });
    setUsers(options);
  }, [fetchedUsers.response]);

  useEffect(() => {
    jira.setCurrentIssue(jira.jiraId).then(() => setTitle(`${jira.jiraId} ${jira.issueName}`));
    // eslint-disable-next-line
  }, [jira.jiraId]);

  useEffect(() => {
    jira.runDetails = {
      ...jira.runDetails,
      [slug]: {
        title, selectedEnvironments, userId,
      },
    };
    // eslint-disable-next-line
  }, [title, selectedEnvironments, userId]);

  return (
    <>
      <If condition={showAdvanced && showMultiSelect}>
        <h3 className="mt-2">{project}</h3>
        <div>
          {selectedEnvironments.map((item, index) => {
            return (
              <div className="flex space-x-2">
                <Select
                  className="w-full multi-select mt-2 mb-2"
                  classNamePrefix="react-select"
                  options={environments}
                  placeholder="Environment"
                  isMulti
                  value={item}
                  onChange={(env) => {
                    selectedEnvironments.splice(index, 1, env);
                    setSelectedEnvironments([...selectedEnvironments]);
                  }}
                  maxMenuHeight={150}
                />
                <Button
                  size="small"
                  appearance="primary"
                  className="mt-2 ml-2"
                  onClick={() => {
                    if (index !== 0) {
                      selectedEnvironments.splice(index, 1);
                      setSelectedEnvironments([
                        ...selectedEnvironments,
                      ]);
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          })}
          <div className="flex space-x-2">
            <Button
              size="small"
              appearance="primary"
              className="mt-2 ml-2"
              onClick={() => {
                selectedEnvironments.push([]);
                setSelectedEnvironments([...selectedEnvironments]);
              }}
            >
              Add environment
            </Button>
            <Button
              size="small"
              appearance="primary"
              className="mt-2 ml-2"
              onClick={() => setShowMultiSelect(false)}
            >
              Back
            </Button>
          </div>
        </div>
      </If>
      <If condition={showAdvanced && runId === undefined && !showMultiSelect}>
        <div>
          <h3 className="mt-2">{project}</h3>
          <div className="flex space-x-2">
            <If condition={selectedEnvironments.length <= 1}>
              <Select
                className="w-full multi-select mt-2 mb-2"
                classNamePrefix="react-select"
                options={environments}
                value={selectedEnvironments[0]}
                placeholder="Environment"
                isMulti
                onChange={(item) => setSelectedEnvironments([item])}
                maxMenuHeight={130}
              />
              <Button
                size="small"
                appearance="primary"
                className="mt-2 ml-2"
                onClick={() => setShowMultiSelect(true)}
              >
                Add
              </Button>
            </If>
            <If condition={selectedEnvironments.length > 1}>
              <Button
                appearance="link"
                onClick={() => setShowMultiSelect(true)}
              >
                {selectedEnvironments.length}
                {' '}
                environments configured
              </Button>
            </If>
          </div>
          <Select
            className="w-full single-select mt-2 mb-2"
            classNamePrefix="react-select"
            options={users}
            placeholder="Assign user"
            onChange={(item) => setUserId(item.value)}
            maxMenuHeight={125}
          />
          <TextField
            name="title"
            label="Title"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </If>
    </>
  );
});

AllProjectsRun.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  testomatioURL: PropTypes.string.isRequired,
  testMap: PropTypes.any.isRequired,
  tests: PropTypes.array.isRequired,
};

export default AllProjectsRun;
