import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalTransition, ModalFooter, ModalBody } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Checkbox } from '@atlaskit/checkbox';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Toggle from '@atlaskit/toggle';
import Spinner from '@atlaskit/spinner';
import { TestBadge } from '../service/tableService';
import If from './If';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import { useStore } from '../store';

const ManualRun = observer(
  ({
    showModal, closeModal, testomatioURL, testMap, tests, planId
  }) => {
    // const testomatioFetch = useTestomatioFetch();
    const [selectedTests, setSelectedTests] = useState(testMap);
    const [loading, setLoading] = useState(false);
    const [runId, setRunId] = useState(undefined);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [selectedEnvironments, setSelectedEnvironments] = useState([]);
    const [showMultiSelect, setShowMultiSelect] = useState(false);
    const { jira, user } = useStore();
    const history = useHistory();
    const [disabledButton, setDisabledButton] = useState(false);
    const [title, setTitle] = useState('');
    const [noTests, setNoTests] = useState(false);
    const [isAutomated, setIsAutomated] = useState(false);
    const [manualTests, setManualTests] = useState([]);
    const [autoManualTests, setAutoManualTests] = useState([]);
    const [allSelect, setAllSelect] = useState(false);
    const [isChecklist, setIsChecklist] = useState(false);

    const fetchedEnvironments = useTestomatioFetch(
      `/api/projects/${jira.testomatioProject.slug}`,
      {
        method: 'GET',
      },
    );

    useEffect(() => {
      setLoading(true);
      const manual = [];
      const auto = [];
      if (tests) tests.forEach(test => {
        if (test.type === 'manual') manual.push(test);
        if (test.type === 'automated') auto.push(test);
      });
      setManualTests(manual);
      setAutoManualTests([...manual, ...auto]);
      setLoading(false);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      jira.setCurrentIssue(jira.jiraId).then(() => setTitle(`${jira.jiraId} ${jira.issueName}`));
      // eslint-disable-next-line
    }, [jira.jiraId]);

    useEffect(() => {
      if (!fetchedEnvironments.response) return;
      const newEnvironments = fetchedEnvironments.response?.attributes?.environments || [];
      const options = newEnvironments.map((env) => ({
        label: env,
        value: env,
      }));
      setEnvironments(options);
    }, [fetchedEnvironments.response]);

    const fetchedUsers = useTestomatioFetch('/users', {
      method: 'GET',
      asHash: true,
      prefix: true,
    });

    useEffect(() => {
      if (!fetchedUsers.response) return;
      const allUsers = fetchedUsers.response;
      const options = allUsers.map((userData) => {
        return { label: userData.email, value: userData.id };
      });
      setUsers(options);
      // eslint-disable-next-line
    }, [fetchedUsers.response]);

    const checkboxCallback = (e) => {
      if (!selectedTests[e.target.value]) {
        selectedTests[e.target.value] = true;
        setSelectedTests({ ...selectedTests });
      } else {
        selectedTests[e.target.value] = false;
        setSelectedTests({ ...selectedTests });
      }
      const testIds = Object.keys(selectedTests).filter(test => !!selectedTests[test]);
      const falseTests = Object.keys(selectedTests).filter(test => !selectedTests[test]);
      if (testIds.length === 0) {
        setDisabledButton(true);
        setAllSelect(false);
      } else {
        setDisabledButton(false);
      }

      if (falseTests.length > 0) {
        setAllSelect(false);
      } else {
        setAllSelect(true);
      }
    };

    const createRun = async () => {
      setLoading(true);
      const env = selectedEnvironments.length === 1
        ? selectedEnvironments[0].map((item) => item.value).join(',')
        : undefined;
      const envs = selectedEnvironments.length > 1
        ? selectedEnvironments.map((environment) => {
          return environment.map((item) => item.value).join(',');
        })
        : undefined;

      const testIds = Object.keys(selectedTests || {}).filter(test => !!selectedTests[test]);
      const newSet = new Set(testIds);

      const finalTests = isAutomated ? testIds : manualTests.filter(test => newSet.has(test.id)).map(item => item.id);

      const body = {
        type: 'runs',
        attributes: {
          automated: false,
          multienv: !!envs,
          'test-ids': planId ? undefined : finalTests,
          'assigned-to': user.userId || undefined,
          assistants: userId,
          title: title || undefined,
          env,
          envs,
          checklist: isChecklist,
        },
        jira_id: jira.jiraId,
      };

      if (planId) {
        body.relationships = {
          plan: {
            data: {
              type: 'plan',
              id: planId,
            },
          },
        }
      }

      const response = await jira.testomatioRequest('/runs', {
        method: 'POST',
        body: JSON.stringify({ data: body }),
      });

      if (response && response.data.id) {
        setRunId(response.data.id);
        jira.runDetails = response;
        closeModal(true);
        history.replace(`/manual_run_page/${response.data.id}/_`);
      }
      setLoading(false);
    };

    useEffect(() => {
      if (planId) {
        setNoTests(false);
        setDisabledButton(false);
        setShowAdvanced(true);
        setIsAutomated(true)
      } else if (!!isAutomated && autoManualTests.length === 0) {
        setNoTests(true);
        setDisabledButton(true);
      } else if (!isAutomated && manualTests.length === 0) {
        setNoTests(true);
        setDisabledButton(true);
      } else {
        setNoTests(false);
        setDisabledButton(false);
      }
      // eslint-disable-next-line
    }, [isAutomated, autoManualTests, manualTests, planId]);

    const selectAll = useCallback(() => {
      const testIds = Object.keys(selectedTests);
      if (!allSelect) {
        testIds.forEach(item => {
          selectedTests[item] = true;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(true);
        setDisabledButton(false);
      } else {
        testIds.forEach(item => {
          selectedTests[item] = false;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(false);
        setDisabledButton(true);
      }
    }, [selectedTests, allSelect]);

    const CustomFooter = () => {
      return (
        <ModalFooter style={{ padding: 12, justifyContent: 'space-between' }}>
          <If condition={runId === undefined}>
            <If condition={testMap && tests}>
              <Button appearance="link" onClick={() => selectAll()} isDisabled={noTests}>
                Select all
              </Button>
            </If>
            <div className="flex space-x-2">
              <If condition={testMap && tests}>
                <Button
                  size="small"
                  appearance="link"
                  spacing="compact"
                  className="ml-2"
                  isDisabled={noTests}
                  onClick={() => {
                    setShowAdvanced(!showAdvanced);
                  }}
                >
                  {showAdvanced ? 'Close' : 'Advanced'}
                  {' '}
                  settings
                </Button>
              </If>

              <Button
                size="small"
                appearance="primary"
                spacing="compact"
                className="mx-2"
                onClick={() => createRun()}
                isLoading={loading}
                isDisabled={disabledButton}
              >
                Create Run
              </Button>
              <Button appearance="default" spacing="compact" size="small" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </If>
        </ModalFooter>
      );
    };

    return (
      <ModalTransition>
        <If condition={showModal}>
          <Modal
            onClose={closeModal}
            scrollBehavior="inside-wide"
            height={600}
            width="x-large"
            components={{ Footer: CustomFooter }}
          >
            <ModalBody>
            <div>
              <div className="flex justify-between">
                <h2 className="text-base font-bold">
                  {showAdvanced ? 'Manual Run Settings' : 'Select tests to run'}
                </h2>
                <div className="flex items-center space-x-2">
                  <div className=" flex items-center">
                    <small className="text-sm">
                      Run as Checklist
                    </small>
                    <Toggle
                      id="toggle-checklist"
                      onChange={() => setIsChecklist(prev => !prev)}
                    />
                  </div>
                  <div className=" flex items-center">
                    <small className="text-sm">
                      Run Automated as Manual
                    </small>
                    <Toggle
                      id="toggle-tooltip"
                      isDisabled={planId}
                      defaultChecked={isAutomated}
                      onChange={() => setIsAutomated(prev => !prev)}
                    />
                  </div>
                </div>
              </div>
              <If condition={showAdvanced && showMultiSelect}>
                <div>
                  {selectedEnvironments.map((item, index) => {
                    return (
                      <div className="flex items-center space-x-1">
                        <Select
                          className="w-full multi-select mt-2 mb-2"
                          classNamePrefix="react-select"
                          options={environments}
                          placeholder="Environment"
                          isMulti
                          value={item}
                          onChange={(env) => {
                            selectedEnvironments.splice(index, 1, env);
                            setSelectedEnvironments([...selectedEnvironments]);
                          }}
                          maxMenuHeight={150}
                        />
                        <Button
                          size="small"
                          appearance="primary"
                          className="mt-2 ml-2"
                          onClick={() => {
                            if (index !== 0) {
                              selectedEnvironments.splice(index, 1);
                              setSelectedEnvironments([
                                ...selectedEnvironments,
                              ]);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    );
                  })}
                  <div className="flex space-x-2">
                    <Button
                      size="small"
                      appearance="primary"
                      className="mt-2 ml-2"
                      onClick={() => {
                        selectedEnvironments.push([]);
                        setSelectedEnvironments([...selectedEnvironments]);
                      }}
                    >
                      Add environment
                    </Button>
                    <Button
                      size="small"
                      appearance="primary"
                      className="mt-2 ml-2"
                      onClick={() => setShowMultiSelect(false)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </If>
              <If
                condition={
                  showAdvanced && runId === undefined && !showMultiSelect
                }
              >
                <div>
                  <div className="flex items-center space-x-1">
                    <If condition={selectedEnvironments.length <= 1}>
                      <Select
                        className="w-full multi-select mt-2 mb-2"
                        classNamePrefix="react-select"
                        options={environments}
                        value={selectedEnvironments[0]}
                        placeholder="Environment"
                        isMulti
                        onChange={(item) => setSelectedEnvironments([item])}
                        maxMenuHeight={150}
                      />
                      <Button
                        size="small"
                        appearance="primary"
                        className="mt-2 ml-2"
                        onClick={() => setShowMultiSelect(true)}
                      >
                        Add
                      </Button>
                    </If>
                    <If condition={selectedEnvironments.length > 1}>
                      <Button
                        appearance="link"
                        onClick={() => setShowMultiSelect(true)}
                      >
                        {selectedEnvironments.length}
                        {' '}
                        environments configured
                      </Button>
                    </If>
                  </div>

                  <Select
                    className="w-full single-select mt-2 mb-2"
                    classNamePrefix="react-select"
                    options={users}
                    placeholder="Assign user"
                    onChange={(item) => setUserId(item.map(t => t.value))}
                    maxMenuHeight={130}
                    isMulti
                  />
                  <TextField
                    name="title"
                    label="Title"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </If>
              <If condition={runId !== undefined}>
                <div className="flex flex-col justify-center mt-20 items-center">
                  <div>
                    <h2 className="text-xl">
                      Run created successfully
                      {' '}
                      <PremiumIcon size="small" />
                    </h2>
                  </div>
                  <a
                    href={`${testomatioURL}/runs/${runId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 font-bold border-none focus: outline-none mt-2"
                  >
                    <Button
                      iconBefore={
                        (iconProps) => <ShortcutIcon {...iconProps} label="Star icon" size="small" />
                      }
                      appearance="primary"
                    >
                      Open in Testomatio
                    </Button>
                  </a>
                </div>
              </If>
              <If condition={runId === undefined && !showAdvanced}>
                <div className="h-full">
                  {!loading && !!isAutomated && autoManualTests.map((test) => {
                    return (
                      <Checkbox
                        value={test.id}
                        label={(
                          <div>
                            {test.title}
                            <span className="ml-2">
                              <TestBadge type={test.type} />
                            </span>
                          </div>
                        )}
                        isChecked={selectedTests[test.id]}
                        onChange={checkboxCallback}
                        key={test.id}
                      />
                    );
                  })}
                  {!loading && !isAutomated && manualTests.map((test) => {
                    return (
                      <Checkbox
                        value={test.id}
                        label={(
                          <div>
                            {test.title}
                            <span className="ml-2">
                              <TestBadge type={test.type} />
                            </span>
                          </div>
                        )}
                        isChecked={selectedTests[test.id]}
                        onChange={checkboxCallback}
                        key={test.id}
                      />
                    );
                  })}
                  {!!loading && !noTests && (
                    <div className="flex justify-center mt-32">
                      <Spinner size="large" />
                    </div>
                  )}
                  {!loading && !!noTests && (
                    <div className="centered">
                      No tests for Run
                    </div>
                  )}
                </div>
              </If>
            </div>
            </ModalBody>
            <CustomFooter />
          </Modal>
        </If>
      </ModalTransition>
    );
  },
);

ManualRun.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  testomatioURL: PropTypes.string.isRequired,
  testMap: PropTypes.any.isRequired,
  tests: PropTypes.array.isRequired,
};

export default ManualRun;
