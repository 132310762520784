import { makeObservable, observable, action } from 'mobx';
import Request from '../service/request';

class UserStore {
  constructor(globalStore) {
    this.jiraStore = globalStore.jira;
    const schemaStr = (window.testomatioConfig && window.testomatioConfig.backendUrlSchema && (window.testomatioConfig.backendUrlSchema === 'http://')) ? 'http://' : 'https://';
    this.testomatioURL = (window.testomatioConfig && window.testomatioConfig.backendUrl) ? (schemaStr + window.testomatioConfig.backendUrl) : undefined;

    this.request = new Request(this.jiraStore.testomatioToken);

    // computed properties
    this.isLoading = false;
    this.isLoggedIn = false;
    this.currentUser = null;
    this.hasRestrictedAccess = false;
    this.isReadOnly = false;
    this.userId = '';

    makeObservable(this, {
      isLoading: observable,
      isLoggedIn: observable,
      currentUser: observable,
      isReadOnly: observable,
      verify: action,
    });
  }

  restrictAccess() {
    if (this.hasRestrictedAccess) return;
    // this.jiraStore.testomatioToken = false;
    this.isLoggedIn = false;
    this.hasRestrictedAccess = true;
    console.log('is logged', this.isLoggedIn);
    this.jiraStore.toast = { type: 'warning', message: 'You are not a member of this project. Read-only access enabled' };
  }

  readOnlyMode() {
    if (this.hasRestrictedAccess) return;
    this.isLoggedIn = false;
    this.hasRestrictedAccess = true;
    this.jiraStore.toast = { type: 'warning', message: 'Read-only access enabled' };
  }

  async getReadOnly() {
    if (!this.jiraStore.testomatioProject || !this.currentUser) return;
    let res;
    try {
      res = await this.request.send(`/api/${this.jiraStore.testomatioProject.slug}/users/${this.userId}`, { method: 'GET' });
      this.isReadOnly = res.data.attributes['is-readonly'];
      return true;
    } catch (e) {
      return false;
    }
  }

  async loginWithPopup(provider) {

    function generateRandomString(minLength = 20, maxLength = 40) {
      const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
      let randomString = '';
      while (randomString.length < length) {
        const randomValues = new Uint8Array(1);
        randomValues[0] = Math.floor(Math.random() * 256);
        randomString += btoa(String.fromCharCode(...randomValues)).replace(/[^A-Za-z0-9]/g, '');
      }
      return randomString.slice(0, length);
    }

    function objectToQueryString(obj) {
      return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
    }

    console.log('loginWithPopup provider:', provider, 'start getCurrentUser');
    await this.jiraStore.getCurrentUser();
    const user = this.jiraStore.currentUser;
    if (!user) {
      console.error('Not logged in into Jira');
      this.jiraStore.toast = { type: 'error', message: 'Not logged in into Jira' };
      return;
    }
    console.log('loginWithPopup user:', user.atlassianAccountId || user.username);
    await this.jiraStore.getCurrentProject();
    const projectKey = this.jiraStore.currentProject?.key;
    console.log('loginWithPopup projectKey:', projectKey);
    const domainUri = new URL(process.env.PUBLIC_URL, window.location.href);
    const cookieName = this.jiraStore.testomatCookieName(this.testomatioURL, 'jwt');
    const keyPropName = 'key_' + cookieName;
    const randomKey = generateRandomString();
    this.jiraStore.setPluginCookie(keyPropName, randomKey);
    const loadedKey = this.jiraStore.getPluginCookie(keyPropName);
    console.log('loginWithPopup loadedKey:', loadedKey, 'randomKey:', randomKey);
    if (!(loadedKey === randomKey)) {
      console.error('ERROR while get just saved cookie. saved=', randomKey, 'loaded=', loadedKey);
      this.jiraStore.toast = { type: 'error', message: 'Can not save randomKey to cookie key_jwt.' };
      return;
    }
    const queryParams = {
      account_id: user.atlassianAccountId || user.username,
      pluginUrl: domainUri.toString(),
      cookieName, randomKey, projectKey
    };
    const query = objectToQueryString(queryParams);
    const newWinUrl = `${this.testomatioURL}/api/${provider}_login?${query}`;
    console.log('loginWithPopup newWinUrl:', newWinUrl);
    const newWin = window.open(newWinUrl);
    const timer = setInterval(() => {
      if (newWin.closed) {
        clearInterval(timer);
        window.location.reload();
      }
    }, 1000);
  }

  async login(email, password) {
    this.isLoading = true;
    const loginOptions = {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: { 'Content-Type': 'application/json' },
    };

    try {
      const response = await fetch(`${this.testomatioURL}/api/login`, loginOptions);
      const data = await response.json();
      if (data.error) throw new Error(data.error);
      if (!(data.jwt && (data.jwt.length > 0))) throw new Error("Wrong server response");

      const cookieName = this.jiraStore.testomatCookieName(this.testomatioURL, 'jwt');
      this.jiraStore.setPluginCookie(cookieName, data.jwt);
      let loaded_jwt = this.jiraStore.getPluginCookie(cookieName);
      if (!(loaded_jwt === data.jwt)) {
        throw new Error("Authorized successfully, but Cookies not allowed by your browser permissions or not working correct");
      }

      this.jiraStore.testomatioToken = data.jwt;
      this.request = new Request(this.jiraStore.testomatioToken);
      return data;
    } catch (e) {
      this.jiraStore.toast = {
        type: 'error',
        message: e.message,
      };
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  async logout() {
    const cookieName = this.jiraStore.testomatCookieName(this.testomatioURL, 'jwt');
    this.jiraStore.setPluginCookie(cookieName, '');
    try {
      this.isLoading = true;

      const jiraUser = this.jiraStore.currentUser;
      await this.request.send('/api/logout', {
        method: 'DELETE',
        body: JSON.stringify({
          account_id: jiraUser.atlassianAccountId || jiraUser.username,
        }),
      });
      this.isLoggedIn = false;
      this.jiraStore.testomatioToken = null;
    } catch (err) {
      this.jiraStore.toast = {
        type: 'error',
        message: err,
      };
    } finally {
      this.isLoading = false;
    }
  }

  async verify() {
    const { jiraStore } = this;
    this.triedToVerify = false;
    if (!jiraStore.testomatioToken) {
      this.isLoading = false;
      this.isLoggedIn = false;
      return false;
    }
    this.isLoading = true;
    this.request = new Request(jiraStore.testomatioToken);
    try {
      const data = await this.request.send('/api/verify', { method: 'GET' });
      this.isLoggedIn = true;
      this.currentUser = data.user.data.attributes;
      this.userId = data.user.data.id;
      return true;
    } catch (e) {
      jiraStore.toast = {
        type: 'error',
        message: e.message,
      };
      console.error('error: ', e); // eslint-disable-line
      return false;
    } finally {
      this.triedToVerify = true;
      this.isLoading = false;
    }
  }
}

export default UserStore;
