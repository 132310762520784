import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Modal, { ModalTransition, ModalHeader, ModalBody } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import DynamicTable from '@atlaskit/dynamic-table';
import Textfield from '@atlaskit/textfield';
import Pagination from '@atlaskit/pagination';
import { useStore } from '../store';
import { createPeakSuiteHead, createPeakSuiteRows } from '../service/tableService';

// hooks
// import useTestomatioFetch from '../hooks/useTestomatioFetch';
import useModel from '../hooks/useModel';

// components
import FormTest from '../components/FormTest';
import If from '../components/If';

const CreateFeature = observer(() => {
  const valueGetter = useRef();
  const history = useHistory();
  const { jira } = useStore();
  const [modal, showModal] = useState(false);
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [suiteCreating, setSuiteCreating] = useState(false);
  const [title, setTitle] = useState('');
  const [suites, setSuites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagesArray, setPagesArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState(null);

  useModel('project', 'issue');

  useEffect(() => {
    jira.getAssociatedSuites();
  }, [jira, jira.issueId]);

  const fetchedSuites = useCallback(async (page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    const { data, meta } = await jira.testomatioRequest(`/suites?file_type=folder&page=${page}`, {
      method: 'GET',
    });
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data
      .filter(s => s.attributes['file-type'] === 'folder')
      .map((suiteData) => {
        const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
        return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
      });
    const root = { label: 'Root', value: null, path: '' };
    setSuites([root, ...suiteItems]);
    setLoading(false);
  }, [jira, currentPage]);

  const searchSuites = useCallback(async (inputValue, page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    setSearchInput(inputValue);
    const { data, meta } = await jira.testomatioRequest(`/suites?file_type=folder&query=${inputValue}&page=${page}`, {
      method: 'GET',
    });
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data.map((suiteData) => {
      const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
      return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
    });
    setSuites(suiteItems);
    setLoading(false);
  }, [jira, currentPage]);

  const createSuite = async (titleData, description, suiteId) => {
    const body = {
      type: 'suites',
      attributes: {
        title: titleData,
        description,
        sync: false,
        'file-type': 'file',
      },
      jira_id: jira.jiraId,
    };

    if (suiteId && suiteId.length > 0) {
      body.attributes.parent_id = suiteId;
    }

    const response = await jira.testomatioRequest(`/suites?jira_id=${jira.jiraId}`, {
      method: 'POST',
      success: 'Suite was created and linked to this issue',
      body: JSON.stringify({ data: body }),
    });
    if (response) {
      if (jira.jiraId) history.goBack();
      else history.replace('/panel');
    }
    setSuiteCreating(false);
    // eslint-disable-next-line
  };

  const createBddSuite = (description, suiteId) => {
    const regexArray = description && description.match(/Feature:(.*)/);
    if (!regexArray) {
      jira.toast = { type: 'error', message: 'Feature file is empty' };
      setSuiteCreating(false);
      showModal(false);
      return;
    }
    const titleData = regexArray[1];
    if (titleData) {
      createSuite(titleData.trim(), description, suiteId);
    } else {
      setSuiteCreating(false);
    }
  };

  const onSave = () => {
    setSuiteCreating(true);
    const desc = valueGetter.current;
    if (jira.isGherkin) {
      createBddSuite(desc, selectedSuite.value);
    } else {
      createSuite(title, desc, selectedSuite.value);
    }
  };

  const onCancel = useCallback(() => {
    setSuites([]);
    setCurrentPage(1);
    setPagesArray([]);
    setLoading(false);
    showModal(false);
  }, []);

  return (
    <div>
      <div className="flex mb-4 shadow-sm justify-between">
        <h3>
          New
          {' '}
          {jira.isGherkin ? 'Feature' : 'Suite'}
        </h3>

        <div className="actions flex space-x-2">
          <Button
            appearance="primary"
            onClick={() => {
              showModal(true);
              fetchedSuites();
            }}
            isLoading={suiteCreating}
          >
            Create
          </Button>
          <Button appearance="default" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </div>
      <FormTest
        kind="suite"
        bodyGetter={valueGetter}
        title={title}
        setTitle={setTitle}
      />

      <ModalTransition>
        <If condition={modal}>
          <Modal
            onClose={onCancel}
            scrollBehavior="inside-wide"
            height={500}
            width={800}
            autoFocus={false}
          >
            <ModalHeader>
              <div className="flex w-full items-center space-x-2 justify-between">
              <Textfield
                  className="w-2/3"
                  placeholder="Start typing title of suite"
                  onChange={e => searchSuites(e.target.value)}
                />
                <div className="flex items-center space-x-2">
                  <If condition={selectedSuite}>
                    <Button appearance="primary" isDisabled={suiteCreating} onClick={() => onSave()}>
                      Save
                    </Button>
                  </If>
                  <Button appearance="default" isDisabled={suiteCreating} onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <If condition={suiteCreating}>
                <div className="flex justify-center mt-32">
                  <Spinner size="large" />
                </div>
              </If>
              <If condition={!suiteCreating}>
                
                <DynamicTable
                  head={createPeakSuiteHead()}
                  emptyView={<div>No Data</div>}
                  rows={createPeakSuiteRows(suites, selectedSuite, setSelectedSuite)}
                  defaultPage={1}
                  loadingSpinnerSize="large"
                  isLoading={loading}
                  isFixedSize
                  defaultSortKey="path"
                  defaultSortOrder="ASC"
                />
                <Pagination
                  style={{ justifyContent: 'center' }}
                  pages={pagesArray}
                  selectedIndex={currentPage - 1}
                  defaultSelectedIndex={0}
                  onChange={(e, page) => {
                    if (searchInput) {
                      searchSuites(searchInput, page);
                      return;
                    }
                    fetchedSuites(page);
                  }}
                />
              </If>
            </ModalBody>
            
          </Modal>
        </If>
      </ModalTransition>
    </div>
  );
});

export default CreateFeature;
