/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

// components
import EditIcon from '@atlaskit/icon/glyph/edit';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Tooltip from '@atlaskit/tooltip';
import Badge from '@atlaskit/badge';
import {
  B400,
  B75,
  Y75,
  R300,
  N300,
  N200,
  R200,
  Y200,
  G200,
  G500,
  R50,
  G50,
  Y400,
  DN0,
  Y100,
} from '@atlaskit/theme/colors';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorDividerIcon from '@atlaskit/icon/glyph/editor/divider';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings'
import { Radio } from '@atlaskit/radio';
import config from '../config';
import If from '../components/If';
import IfLoggedIn from '../components/IfLoggedIn';
import StatusGroup from '../components/StatusGroup';
import { Checkbox } from '@atlaskit/checkbox';
import { useStore } from '../store/';

export const createTestHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'id',
        content: 'Id',
        isSortable: false,
        shouldTruncate: true,
        width: 7,
      },
      {
        key: 'title',
        content: 'Title',
        isSortable: true,
        shouldTruncate: true,
        width: 16,
      },
      {
        key: 'type',
        content: 'Type',
        shouldTruncate: false,
        isSortable: true,
        width: 6,
      },
      {
        key: 'status',
        content: 'Status',
        shouldTruncate: false,
        isSortable: false,
        width: 5,
      },
      {
        key: 'priority',
        content: 'Priority',
        shouldTruncate: false,
        isSortable: true,
        width: 5,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
    ],
  };
};

export const createTestTableRows = ({ array, url, slug, deleteHandle, checkHandle, selectedTests }) => {
  return array.map((testDetail) => {
    return {
      key: `row-${testDetail.id}`,
      cells: [
        {
          key: 'id',
          content: (
            <Title title={testDetail.id} url={`${url}/test/${testDetail.id}`} />
          ),
        },
        {
          key: `${testDetail.title}`,
          content: (
            <div className="text-sm">
              {testDetail.branch && (
                <Tooltip tag="span" content={testDetail.branch.title}>
                  <span className="mr-2 inline-block align-middle truncate text-xs px-2" style={{ maxWidth: '60px' }}>
                    {testDetail.branch.title}
                  </span>
                </Tooltip>
              )}
              <Tooltip tag="span" content={testDetail.title} delay={400}>
                  {testDetail.title}
              </Tooltip>
            </div>
          ),
        },
        {
          key: `${testDetail.type}`,
          content: <TestBadge type={testDetail.type} />,
        },
        {
          key: 'status',
          content: <Status statuses={testDetail.status} />,
        },
        {
          key: `${testDetail.priority || 'none'}`,
          content: <TestBadge type={testDetail.priority || 'none'} />,
        },
        {
          key: '',
          content: (
            <div className="flex space-x-2 whitespace-nowrap">
              <Link
                to={`view_test/${slug}/${testDetail.branch_slug || config.mainBranchSlug}/${testDetail.id}`}
              >
                <WatchFilledIcon size="small" />
              </Link>
              <IfLoggedIn showDisabled>
                <Link to={`edit_test/${slug}/${testDetail.branch_slug || config.mainBranchSlug}/${testDetail.id}`} >
                  <EditIcon size="small" />
                </Link>
                <If condition={deleteHandle}>
                  <button
                    onClick={() => deleteHandle(testDetail.id)}
                    type="button"
                    className="focus:outline-none"
                  >
                    <TrashIcon size="small" />
                  </button>
                </If>
              </IfLoggedIn>
              <If condition={checkHandle}>
                  <Checkbox
                    value={testDetail.id}
                    isChecked={selectedTests[testDetail.id]}
                    onChange={e => checkHandle(e, testDetail.id)}
                    key={testDetail.id}
                  />
              </If>
            </div>
          ),
        },
      ],
    };
  });
};

export const createSuiteHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'id',
        content: 'Id',
        isSortable: false,
        shouldTruncate: true,
        width: 3,
      },
      {
        key: 'title',
        content: 'Title',
        isSortable: true,
        shouldTruncate: true,
        width: 20,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
    ],
  };
};

export const createSuiteRows = (suiteDetailArray, url, deleteHandle, slug) => {
  return suiteDetailArray.map((suiteDetail) => {
    return {
      key: `row-${suiteDetail.id}`,
      cells: [
        {
          key: 'id',
          content: (
            <Title
              title={suiteDetail.id}
              url={`${url}/suite/${suiteDetail.id}`}
            />
          ),
        },
        {
          key: `${suiteDetail.title}`,
          content: (
            <div className="text-sm">
              {suiteDetail.branch && (
                <Tooltip tag="span" content={suiteDetail.branch.title}>
                  <span className="mr-2 inline-block align-middle truncate text-xs px-2" style={{ maxWidth: '60px' }}>
                    {suiteDetail.branch.title}
                  </span>
                </Tooltip>
              )}
              {suiteDetail.emoji}
              {' '}
              <Tooltip tag="span" content={suiteDetail.title} delay={400}>
                <span>
                  {suiteDetail.title}
                </span>
              </Tooltip>
            </div>
          ),
        },
        {
          key: '',
          content: (
            <div className="flex space-x-2 whitespace-nowrap">
              <Link
                to={`view_suite/${suiteDetail.project}/${suiteDetail.branch_slug || config.mainBranchSlug}/${suiteDetail.id}`}
                className="mr-1"
              >
                <WatchFilledIcon size="small" />
              </Link>
              {' '}
              <IfLoggedIn showDisabled>
                <Link to={`edit_suite/${slug}/${suiteDetail.branch_slug || config.mainBranchSlug}/${suiteDetail.id}`} className="mr-1">
                  <EditIcon size="small" />
                </Link>
                {' '}
                <button
                  onClick={() => deleteHandle(suiteDetail.id)}
                  type="button"
                  className="focus:outline-none"
                >
                  <TrashIcon size="small" />
                </button>
              </IfLoggedIn>
            </div>
          ),
        },
      ],
    };
  });
};

export const createAllSuiteHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'id',
        content: 'Id',
        isSortable: false,
        shouldTruncate: true,
        width: 5,
      },
      {
        key: 'title',
        content: 'Title',
        isSortable: true,
        shouldTruncate: true,
        width: 17,
      },
      {
        key: 'project',
        content: 'Project',
        shouldTruncate: true,
        isSortable: true,
        width: 6,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 5,
      },
    ],
  };
};

export const createAllSuiteRows = (suiteDetailArray, url) => {
  return suiteDetailArray.map((suiteDetail) => {
    return {
      key: `row-${suiteDetail.id}`,
      cells: [
        {
          key: 'id',
          content: (
            <Title
              title={suiteDetail.id}
              url={`${url}/projects/${suiteDetail.project}/suite/${suiteDetail.id}`}
            />
          ),
        },
        {
          key: `${suiteDetail.title}`,
          content: (
            <div className="text-sm">
              {suiteDetail.branch && (
                <Tooltip tag="span" content={suiteDetail.branch.title}>
                  <span className="mr-2 inline-block align-middle truncate text-xs px-2" style={{ maxWidth: '60px' }}>
                    {suiteDetail.branch.title}
                  </span>
                </Tooltip>
              )}
              {suiteDetail.emoji}
              {' '}
              <Tooltip tag="span" content={suiteDetail.title} delay={400}>
                <span>
                  {suiteDetail.title}
                </span>
              </Tooltip>
            </div>
          ),
        },
        {
          key: `${suiteDetail.projectName}`,
          content: (
            <span className="text-sm">{suiteDetail.projectName}</span>
          ),
        },
        {
          key: '',
          content: (
            <div className="flex space-x-2 whitespace-nowrap">
              <Link
                to={`view_suite/${suiteDetail.project}/${suiteDetail.branch_slug || config.mainBranchSlug}/${suiteDetail.id}`}
                className="mr-1"
              >
                <WatchFilledIcon size="small" />
              </Link>
            </div>
          ),
        },
      ],
    };
  });
};

export const createIssueHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'title',
        content: 'Title',
        isSortable: false,
        shouldTruncate: true,
        width: 15,
      },
      {
        key: 'tests',
        content: 'Tests',
        shouldTruncate: false,
        isSortable: true,
        width: 6,
      },
      {
        key: 'status',
        content: 'Status',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
      {
        key: 'autoStatus',
        content: 'Automation status',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
    ],
  };
};

export const createEpicViewHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'title',
        content: 'Title',
        isSortable: false,
        shouldTruncate: true,
        width: 15,
      },
      {
        key: 'tests',
        content: 'Tests',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
      {
        key: 'status',
        content: 'Status',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
      {
        key: 'action',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
    ],
  };
};

export const Text = ({ data }) => {
  return <span className="text-sm">{data}</span>;
};

const Title = ({ title, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-sm text-blue-600 font-bold"
    >
      <ShortcutIcon size="small" />
      {' '}
      {title}
    </a>
  );
};

const getTypeColor = (type, theme) => {
  if (type === 'automated') {
    return { text: B400, bg: B75 };
  }
  if (type === 'manual') {
    if (theme === 'dark') {
      return { text: DN0, bg: Y100  };
    } else {
      return { text: R300, bg: Y75 };
    }
  }
  if (type === 'PASSED') {
    return { text: G500, bg: G50 };
  }
  if (type === 'FAILED') {
    return { text: R300, bg: R50 };
  }
  if (type === 'low') {
    return { text: B400, bg: B75 };
  }
  if (type === 'normal') {
    if (theme === 'dark') {
      return { text: '#fff', bg: N300 };
    } else {
      return { text: 'inherit', bg: '#eee' };
    }
  }
  if (type === 'critical') {
    if (theme === 'dark') {
      return { text: '#fff', bg: R200 };
    } else {
      return { text: R300, bg: R50 };
    }
  }
  if (type === 'high') {
    if (theme === 'dark') {
      return { text: R300, bg: Y100  };
    } else {
      return { text: R300, bg: Y75 };
    }
  }
  if (type === 'important') {
    return { text: Y400, bg: Y75 };
  }

  return { text: 'inherit', bg: '#cbd5e0' };
};

export const TestBadge = ({ type }) => {
  const { jira } = useStore();
  const color = getTypeColor(type, jira.theme);
  return (
    <Badge style={{ backgroundColor: color.bg, color: color.text }}>
      {type}
    </Badge>
  );
};

const getStatusColor = (status) => {
  if (status === 'passed') return G200;
  if (status === 'failed') return R200;
  if (status === 'skipped') return Y200;

  return N200;
};

const Status = ({ statuses }) => {
  if (!statuses) return (<></>);

  return (
    <span className="flex">
      {statuses.map((statusStr, index) => {
        return (
          <div
            style={{ backgroundColor: getStatusColor(statusStr) }}
            className="h-2 w-2 rounded-full mr-1"
            key={`${index}-${statusStr}`}
          />
        );
      })}
    </span>
  );
};

export const createRunsHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'suite',
        content: 'Suite',
        shouldTruncate: true,
        isSortable: false,
        width: 7,
      },
      {
        key: 'title',
        content: 'Title',
        isSortable: false,
        shouldTruncate: true,
        width: 22,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 9,
      },
    ],
  };
};

export const createRunsTableRows = (runsDetailArray, slug) => {
  return runsDetailArray.filter(item => item.type === 'testrun').map((runsDetail, i) => {
    let example = '';
    if (runsDetail.attributes.example) {
      runsDetail.attributes.example.forEach(item => {
        example += ` [${item}]`;
      });
    }

    return {
      key: `row-${runsDetail.id}`,
      cells: [
        {
          key: 'suite',
          content: (
            <span>{runsDetail.attributes.test.suite.title}</span>
          ),
        },
        {
          key: 'title',
          content: (
            <div className="text-sm truncate">
              {runsDetail.attributes.test.description ? (
                <Tooltip tag="span" content={runsDetail.attributes.test.description}>
                  <>
                    <span>{runsDetail.attributes.test.title}</span>
                    <If condition={runsDetail.attributes.example}>
                      <code className="text-xs">{example}</code>
                    </If>
                  </>
                </Tooltip>
              ) : (
                <>
                  <span>
                    {runsDetail.attributes.test.title}
                  </span>
                  <If condition={runsDetail.attributes.example}>
                    <code className="text-xs">{example}</code>
                  </If>
                </>
              )}
            </div>
          ),
        },
        {
          key: 'action',
          content: (
            <StatusGroup runsID={runsDetail.id} i={i} slug={slug} />
          ),
        },
      ],
    };
  });
};

export const testRunsHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'url',
        content: 'URL',
        isSortable: false,
        shouldTruncate: false,
        width: 1,
      },
      {
        key: 'title',
        content: 'Title',
        shouldTruncate: true,
        isSortable: false,
        width: 22,
      },
      {
        key: 'data',
        content: 'Data',
        shouldTruncate: true,
        isSortable: true,
        width: 6,
      },
      {
        key: 'passed',
        content: (
          <div className="-ml-1">
            <CheckIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'failed',
        content: (
          <div className="-ml-1">
            <CrossIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'skipped',
        content: (
          <div>
            <EditorDividerIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 3,
      },
    ],
  };
};

export const testRunsRows = (testRunsArray, url, slug) => {
  return testRunsArray.map((testRun) => {
    const envElement = testRun.env ? testRun.env.split(',').map(item => (<Badge>{item}</Badge>)) : null;
    const link = testRun.kind === 'manual' ? `/manual_run_page/${testRun.id}/${slug}` : `/mixed_run_page/${testRun.id}/${slug}`;
    const formatDate = new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    let date;
    if (testRun.finished_at) {
      const newDate = new Date(testRun.finished_at);

      date = formatDate.format(newDate);
    } else {
      date = 'Not finished';
    }

    const run_url = `${url}/runs/${(testRun.finished_at) ? `${testRun.id}/report/` : `launch/${testRun.id}/`}`;

    return {
      key: `row-${testRun.id}`,
      cells: [
        {
          key: 'url',
          content: (
            <a
              href={run_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-bold"
            >
              <ShortcutIcon size="small" />
            </a>
          ),
        },
        {
          key: 'title',
          content: (
            <div className="flex space-x-2 flex-row items-center">
              <div
                style={{ backgroundColor: getStatusColor(testRun.status) }}
                className="h-2 w-2 rounded-full ml-1"
              />
              {testRun.branch && (
                <Tooltip tag="span" content={testRun.branch.title}>
                  <span className="mr-2 inline-block align-middle truncate text-xs px-2" style={{ maxWidth: '60px' }}>
                    {testRun.branch.title}
                  </span>
                </Tooltip>
              )}
              <Tooltip tag="div" content={testRun.title} delay={400}>
                <div className="text-sm truncate">{testRun.title}</div>
              </Tooltip>
              <TestBadge type={testRun.kind} />
              <If condition={envElement}>
                {envElement}
              </If>
            </div>
          ),
        },
        {
          key: `${date}`,
          content: (
            <span className="text-xs">{date}</span>
          ),
        },
        {
          key: 'passed',
          content: (
            <div className="text-xs">{testRun.passed_count}</div>
          ),
        },
        {
          key: 'failed',
          content: (
            <div className="text-xs">{testRun.failed_count}</div>
          ),
        },
        {
          key: 'skipped',
          content: (
            <div className="text-xs">{testRun.skipped_count}</div>
          ),
        },
        {
          key: 'actions',
          content: (
            <div className="flex whitespace-nowrap space-x-1">
              <If condition={testRun.status === 'scheduled' || testRun.status === 'running'}>
                <IfLoggedIn showDisabled>
                  <Tooltip tag="span" content="Continue Manual Run">
                    <Link to={link}>
                      <VidPlayIcon size="small" />
                    </Link>
                  </Tooltip>
                </IfLoggedIn>
              </If>
            </div>
          ),
        },
      ],
    };
  });
};

export const createAllProjectsHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'id',
        content: 'Id',
        isSortable: false,
        shouldTruncate: true,
        width: 5,
      },
      {
        key: 'title',
        content: 'Title',
        isSortable: true,
        shouldTruncate: true,
        width: 13,
      },
      {
        key: 'project',
        content: 'Project',
        isSortable: true,
        shouldTruncate: true,
        width: 6,
      },
      {
        key: 'type',
        content: 'Type',
        shouldTruncate: false,
        isSortable: true,
        width: 5,
      },
      {
        key: 'status',
        content: 'Status',
        shouldTruncate: false,
        isSortable: false,
        width: 5,
      },
      {
        key: 'priority',
        content: 'Priority',
        shouldTruncate: false,
        isSortable: true,
        width: 5,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 6,
      },
    ],
  };
};

export const createAllProjectsRows = (testDetailArray, url) => {
  return testDetailArray.map((testDetail) => {
    return {
      key: `row-${testDetail.id}`,
      cells: [
        {
          key: 'id',
          content: (
            <Title title={testDetail.id} url={`${url}/projects/${testDetail.project}/test/${testDetail.id}`} />
          ),
        },
        {
          key: `${testDetail.title}`,
          content: (
            <div className="text-sm">
              {testDetail.branch && (
                <Tooltip tag="span" content={testDetail.branch.title}>
                  <span className="mr-2 inline-block align-middle truncate text-xs px-2" style={{ maxWidth: '60px' }}>
                    {testDetail.branch.title}
                  </span>
                </Tooltip>
              )}
              <Tooltip tag="span" content={testDetail.title} delay={400}>
                <span>
                  {testDetail.title}
                </span>
              </Tooltip>
            </div>
          ),
        },
        {
          key: `${testDetail.projectName}`,
          content: <span className="text-sm">{testDetail.projectName}</span>,
        },
        {
          key: `${testDetail.type}`,
          content: <TestBadge type={testDetail.type} />,
        },
        {
          key: 'status',
          content: <Status statuses={testDetail.status} />,
        },
        {
          key: `${testDetail.priority || 'none'}`,
          content: <TestBadge type={testDetail.priority || 'none'} />,
        },
        {
          key: '',
          content: (
            <div className="flex space-x-2 whitespace-nowrap">
              <Link to={`view_test/${testDetail.project}/${testDetail.branch_slug || config.mainBranchSlug}/${testDetail.id}`}>
                <WatchFilledIcon size="small" />
              </Link>
            </div>
          ),
        },
      ],
    };
  });
};

export const allTestRunsHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'url',
        content: 'URL',
        isSortable: false,
        shouldTruncate: false,
        width: 1,
      },
      {
        key: 'title',
        content: 'Title',
        shouldTruncate: true,
        isSortable: true,
        width: 16,
      },
      {
        key: 'data',
        content: 'Data',
        shouldTruncate: true,
        isSortable: true,
        width: 6,
      },
      {
        key: 'project',
        content: 'Project',
        isSortable: true,
        shouldTruncate: true,
        width: 6,
      },
      {
        key: 'passed',
        content: (
          <div className="-ml-1">
            <CheckIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'failed',
        content: (
          <div className="-ml-1">
            <CrossIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'skipped',
        content: (
          <div>
            <EditorDividerIcon size="small" />
          </div>
        ),
        shouldTruncate: true,
        isSortable: false,
        width: 2,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 3,
      },
    ],
  };
};

export const allTestRunsRows = (allTestRunsArray, url, projectName) => {
  return allTestRunsArray.map((testRun) => {
    const envElement = testRun.env ? testRun.env.split(',').map(item => (<Badge>{item}</Badge>)) : null;
    const link = testRun.kind === 'manual' ? `/manual_run_page/${testRun.id}/${testRun.project}` : `/mixed_run_page/${testRun.id}/${testRun.project}`;
    const formatDate = new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    let date;
    if (testRun.finished_at) {
      const newDate = new Date(testRun.finished_at);
      date = formatDate.format(newDate);
    } else {
      date = 'Not finished';
    }

    return {
      key: `row-${testRun.id}`,
      cells: [
        {
          key: 'url',
          content: (
            <a
              href={`${url}/projects/${testRun.project}/runs/${testRun.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-bold"
            >
              <ShortcutIcon size="small" />
            </a>
          ),
        },
        {
          key: `${testRun.title}`,
          content: (
            <div className="flex space-x-2 flex-row items-center">
              <div
                style={{ backgroundColor: getStatusColor(testRun.status) }}
                className="h-2 w-2 rounded-full ml-1"
              />
              <Tooltip tag="div" content={testRun.title} delay={400}>
                <div className="text-sm truncate">{testRun.title}</div>
              </Tooltip>
              <If condition={envElement}>
                {envElement}
              </If>
            </div>
          ),
        },
        {
          key: `${date}`,
          content: (
            <span className="text-xs">{date}</span>
          ),
        },
        {
          key: `${projectName}`,
          content: <span className="text-sm">{projectName}</span>,
        },
        {
          key: 'passed',
          content: (
            <div className="text-xs">{testRun.passed_count}</div>
          ),
        },
        {
          key: 'failed',
          content: (
            <div className="text-xs">{testRun.failed_count}</div>
          ),
        },
        {
          key: 'skipped',
          content: (
            <div className="text-xs">{testRun.skipped_count}</div>
          ),
        },
        {
          key: 'actions',
          content: (
            <div className="flex whitespace-nowrap space-x-1">
              <If condition={testRun.status === 'scheduled' || testRun.status === 'running'}>
                <IfLoggedIn showDisabled>
                  <Tooltip tag="span" content="Continue Manual Run">
                    <Link to={link}>
                      <VidPlayIcon size="small" />
                    </Link>
                  </Tooltip>
                </IfLoggedIn>
              </If>
            </div>
          ),
        },
      ],
    };
  });
};

export const testPlansHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'url',
        content: 'URL',
        isSortable: false,
        shouldTruncate: false,
        width: 1,
      },
      {
        key: 'title',
        content: 'Title',
        shouldTruncate: true,
        isSortable: false,
        width: 22,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 3,
      },
    ],
  };
};

export const testPlansRows = ({ array, projectUrl, createRun, createRunAdvanced, unlink }) => {
  return array.map((testPlan) => {
    const link = `${projectUrl}/plans/${testPlan.id}/`;
    return {
      key: `row-${testPlan.id}`,
      cells: [
        {
          key: 'url',
          content: (
            <a
              href={`${link}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-bold"
            >
              <ShortcutIcon size="small" />
            </a>
          ),
        },
        {
          key: 'title',
          content: (
            <div className="flex space-x-2 flex-row items-center">
              <div className="text-sm truncate">{testPlan.title}</div>
              <TestBadge type={testPlan.kind} />
            </div>
          ),
        },
        {
          key: 'actions',
          content: (
            <div className="flex whitespace-nowrap space-x-1">
              <IfLoggedIn showDisabled>
                <Tooltip content="Launch a plan">
                  <button type="button" onClick={() => createRun(testPlan.id, testPlan.kind)}>
                    <VidPlayIcon size="small" />
                  </button>
                </Tooltip>
                <If condition={createRunAdvanced}>
                  <Tooltip content="Launch a plan with advanced options">
                    <button type="button" onClick={() => createRunAdvanced(testPlan.id)}>
                      <EditorSettingsIcon size="small" />
                    </button>
                  </Tooltip>
                </If>
                <If condition={unlink}>
                  <button
                    onClick={() => unlink(testPlan.id)}
                    type="button"
                    className="focus:outline-none"
                  >
                    <TrashIcon size="small" />
                  </button>
                </If>
              </IfLoggedIn>
            </div>
          ),
        },
      ],
    };
  });
};

export const allTestPlansHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'url',
        content: 'URL',
        isSortable: false,
        shouldTruncate: false,
        width: 1,
      },
      {
        key: 'title',
        content: 'Title',
        shouldTruncate: true,
        isSortable: true,
        width: 16,
      },
      {
        key: 'project',
        content: 'Project',
        isSortable: true,
        shouldTruncate: true,
        width: 6,
      },
      {
        key: 'actions',
        content: 'Actions',
        shouldTruncate: false,
        isSortable: false,
        width: 3,
      },
    ],
  };
};

export const allTestPlansRows = (allTestPlansArray, url, project, action) => {
  return allTestPlansArray.map((testPlan) => {
    const link = `${url}/projects/${testPlan.project}/plans/${testPlan.id}/`;
    return {
      key: `row-${testPlan.id}`,
      cells: [
        {
          key: 'url',
          content: (
            <a
              href={`${link}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-bold"
            >
              <ShortcutIcon size="small" />
            </a>
          ),
        },
        {
          key: `${testPlan.title}`,
          content: (
            <div className="flex space-x-2 flex-row items-center">
              <div className="text-sm truncate">{testPlan.title}</div>
              <TestBadge type={testPlan.kind} />
            </div>
          ),
        },
        {
          key: `${project.title}`,
          content: <span className="text-sm">{project.title}</span>,
        },
        {
          key: 'actions',
          content: (
            <div className="flex whitespace-nowrap space-x-1">
              <If condition={testPlan.kind === 'manual'}>
                <IfLoggedIn showDisabled>
                  <Tooltip content="Launch a plan">
                    <button type="button" onClick={() => action(testPlan.id, testPlan.kind, project.slug)}>
                      <VidPlayIcon size="small" />
                    </button>
                  </Tooltip>
                </IfLoggedIn>
              </If>
            </div>
          ),
        },
      ],
    };
  });
};

export const createPeakSuiteHead = () => {
  return {
    key: 'col',
    cells: [
      {
        key: 'title',
        content: 'Title',
        isSortable: false,
        shouldTruncate: true,
        width: 15,
      },
      {
        key: 'path',
        content: 'Path',
        shouldTruncate: true,
        isSortable: false,
        width: 15,
      },
    ],
  };
};

export const createPeakSuiteRows = (suiteRows, selectedSuite, setSelectedSuite) => {
  return suiteRows.map(suite => {
    return {
      key: `row-${suite.id}`,
      cells: [
        {
          key: `${suite.label}`,
          content: (
            <div className="flex items-center space-x-2 text-xs">
              <Radio
                isChecked={suite.value === selectedSuite?.value}
                onChange={() => setSelectedSuite(suite)}
                name={suite.label}
                value={suite.value}
              />
              {suite.label}
            </div>
          ),
        },
        {
          key: 'path',
          content: (
            <div className="text-xs truncate">{suite.path}</div>
          ),
        },
      ],
    };
  });
};
