import Modal, { ModalBody } from '@atlaskit/modal-dialog';
import { observer } from 'mobx-react';
import React, { useEffect, useCallback, useState } from 'react';
import PresenceUnavailableIcon from '@atlaskit/icon/glyph/presence-unavailable';
import Button from '@atlaskit/button/new';
import Pagination from '@atlaskit/pagination';
import Spinner from '@atlaskit/spinner';
import { useStore } from '../store';
import If from './If';
import { TestBadge } from '../service/tableService';

const LinkPlan = observer(({ onClose }) => {
  const store = useStore();
  const { jira } = store;
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesArray, setPagesArray] = useState([]);

  const fetchedPlans = useCallback(async (page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    const { data, meta } = await jira.testomatioRequest(`/plans?page=${page}&per_page=20`, {
      method: 'GET',
    });
    if (!data) return;
    setPlans(data.map(plan => {
      return {
        title: plan.attributes.title,
        count: plan.attributes['test-count'],
        kind: plan.attributes.kind,
        id: plan.id,
        jiraIssues: plan.attributes['jira-issues'],
      };
    }));
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
        for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    setLoading(false);
  }, [jira, currentPage]);

  useEffect(() => {
    fetchedPlans();
    // eslint-disable-next-line
    }, []);

  return (
    <Modal
      onClose={onClose}
      scrollBehavior="inside-wide"
      height={600}
      width={800}
    >
      <ModalBody>
      <h2 className="font-bold my-2">Link a plan</h2>
      {!loading && (
      <>
        <ul className="space-y-2 my-2">
          <If condition={plans.length > 0 && !loading}>
            {plans.map((plan) => {
              return (
                <li>
                  <PlanItem plan={plan} />
                </li>
              );
            })}
          </If>
        </ul>
        <Pagination
          className="my-2"
          pages={pagesArray}
          selectedIndex={currentPage - 1}
          defaultSelectedIndex={0}
          onChange={(e, page) => fetchedPlans(page)}
        />
      </>
      )}
      {loading && (
      <div className="flex justify-center mt-32">
        <Spinner size="large" />
      </div>
      )}
      </ModalBody>
    </Modal>
  );
});

const PlanItem = observer(({ plan }) => {
  const [linked, setLinked] = useState(null);
  const [loading, setLoading] = useState(false);
  const { jira } = useStore();

  const linkPlan = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest('/jira/issues', {
      method: 'POST',
      body: JSON.stringify({
        jira_id: jira.jiraId,
        plan_id: plan.id,
      }),
    });
    if (response) setLinked(true);
    setLoading(false);
  }, [jira, plan]);

  const unlinkPlan = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
      method: 'DELETE',
      body: JSON.stringify({ plan_id: plan.id }),
    });
    if (response) setLinked(false);
    setLoading(false);
  }, [jira, plan]);

  useEffect(() => {
    if (!plan) return;
    setLinked(plan.jiraIssues && plan.jiraIssues.map(issue => issue.jira_id).includes(jira.jiraId));
  }, [plan, jira]);

  return (
    <div className="flex space-x-2 items-center">
      <PresenceUnavailableIcon size="small" />
      <span>{plan.title}</span>
      <TestBadge type={plan.kind} />
      {' '}
      <If condition={linked}>
        <Button
          size="small"
          appearance="default"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => unlinkPlan()}
          isLoading={loading}
        >
          Unlink
        </Button>
      </If>
      <If condition={!linked}>
        <Button
          size="small"
          appearance="primary"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => linkPlan()}
          isLoading={loading}
        >
          Link
        </Button>
      </If>
    </div>
  );
});

export default LinkPlan;
