import Modal, { ModalTransition, ModalHeader, ModalBody } from '@atlaskit/modal-dialog';
import { observer } from 'mobx-react';
import React, { useCallback, useState, useEffect } from 'react';
import Textfield from '@atlaskit/textfield';
import DynamicTable from '@atlaskit/dynamic-table';
import Button from '@atlaskit/button/new';
import Pagination from '@atlaskit/pagination';
import { useStore } from '../store';
import { createPeakSuiteHead, createPeakSuiteRows } from '../service/tableService';
import If from './If';

const LinkSuite = observer(({ closeModal }) => {
  const [linkLoading, setLinkLoading] = useState(false);
  const [selectedSuiteItem, setSelectedSuiteItem] = useState(null);
  const { jira } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesArray, setPagesArray] = useState([]);
  const [suites, setSuites] = useState([]);
  const [searchInput, setSearchInput] = useState(null);

  const onSuiteSelect = useCallback(async () => {
    const item = selectedSuiteItem;
    setLinkLoading(true);
    if (item) {
      await jira.testomatioRequest('/jira/issues', {
        method: 'POST',
        success: 'Suite was linked',
        body: JSON.stringify({
          jira_id: jira.jiraId,
          suite_id: item?.value || null,
        }),
      });
    }
    setLinkLoading(false);
    closeModal();
  }, [selectedSuiteItem, jira, closeModal]);

  const fetchedSuites = useCallback(async (page = 1) => {
    setLinkLoading(true);
    setCurrentPage(page);
    const { data, meta } = await jira.testomatioRequest(`/suites?file_type=file&page=${page}`, {
      method: 'GET',
    });
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data.map((suiteData) => {
      const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
      return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
    });
    setSuites(suiteItems);
    setLinkLoading(false);
  }, [jira, currentPage]);

  const searchSuites = useCallback(async (inputValue, page = 1) => {
    setLinkLoading(true);
    setCurrentPage(page);
    setSearchInput(inputValue);
    const { data, meta } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}&page=${page}`, {
      method: 'GET',
    });
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data.map((suiteData) => {
      const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
      return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
    });
    setSuites(suiteItems);
    setLinkLoading(false);
  }, [jira, currentPage]);

  useEffect(() => {
    fetchedSuites();
    // eslint-disable-next-line
  }, [])

  const onCancel = useCallback(() => {
    setSuites([]);
    setCurrentPage(1);
    setPagesArray([]);
    setLinkLoading(false);
    closeModal();
  }, [closeModal]);

  return (
    <ModalTransition>

      <Modal
        onClose={closeModal}
        scrollBehavior="inside-wide"
        height={600}
        width={800}
        autoFocus={false}
      >
        <ModalHeader>
          <div className="flex w-full space-x-2 items-center justify-between my-2">
            <Textfield
                className="w-2/3"
                placeholder="Start typing title of suite"
                onChange={e => searchSuites(e.target.value)}
              />
            <div className="flex items-center space-x-2">
              <If condition={selectedSuiteItem}>
                <Button appearance="primary" isDisabled={linkLoading} onClick={() => onSuiteSelect()}>
                  Save
                </Button>
              </If>
              <Button appearance="default" isDisabled={linkLoading} onClick={() => onCancel()}>
                Cancel
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <DynamicTable
            head={createPeakSuiteHead()}
            emptyView={<div>No Data</div>}
            rows={createPeakSuiteRows(suites, selectedSuiteItem, setSelectedSuiteItem)}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={linkLoading}
            isFixedSize
            defaultSortKey="title"
            defaultSortOrder="ASC"
          />
          <Pagination
            style={{ justifyContent: 'center' }}
            pages={pagesArray}
            selectedIndex={currentPage - 1}
            defaultSelectedIndex={0}
            onChange={(e, page) => {
              if (searchInput) {
                searchSuites(searchInput, page);
                return;
              }
              fetchedSuites(page);
            }}
          />
        </ModalBody>
      </Modal>
    </ModalTransition>
  );
});

export default LinkSuite;
