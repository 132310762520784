import React, { useCallback, useState, useEffect } from 'react';
import Button from '@atlaskit/button/new';
import { observer } from 'mobx-react';
import Modal, {
  ModalFooter,
  ModalTransition,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import { ErrorMessage } from '@atlaskit/form';
import { TestBadge } from '../service/tableService';
import { useStore } from '../store';
import If from './If';
import useRevision from '../hooks/useRevision';

const UnlinkTests = observer(({ closeModal, tests, testMap }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedTests, setSelectedTests] = useState(testMap);
  const [allSelected, setAllSelected] = useState(true);
  const [error, setError] = useState(false);
  const { jira, user } = useStore();
  const [revision, reloadModel] = useRevision();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const checkboxCallback = (e) => {
    setDisabledButton(false);
    if (!selectedTests[e.target.value]) {
      selectedTests[e.target.value] = true;
      setSelectedTests({ ...selectedTests });
    } else {
      selectedTests[e.target.value] = false;
      setSelectedTests({ ...selectedTests });
    }

    const testIds = Object.keys(selectedTests).some(item => {
      return selectedTests[item];
    });
    setDisabledButton(!testIds);
  };

  const selectAll = () => {
    if (!allSelected) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  useEffect(() => {
    const testIds = Object.keys(selectedTests);
    testIds.forEach(item => {
      selectedTests[item] = allSelected;
    });
    setSelectedTests({ ...selectedTests });
    if (!allSelected) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
    // eslint-disable-next-line
  }, [allSelected]);

  const detachFromTestomatio = useCallback(async () => {
    setDeleteLoading(true);
    if (user.isLoggedIn) {
      const testIds = Object.keys(selectedTests).filter(item => {
        return selectedTests[item];
      });
      try {
        await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
          method: 'DELETE',
          prefix: true,
          body: JSON.stringify({ test_ids: testIds, branch: jira.branchId }),
        });
      } catch (e) {
        setError(true);
        console.log(e);
      } finally {
        await setDeleteLoading(false);
        await reloadModel();
        await closeModal();
      }
    }
    // eslint-disable-next-line
  }, [jira, user.isLoggedIn, selectedTests, revision, deleteLoading]);

  return (
    <>
      <ModalTransition>
        <If condition={error}>
          <ErrorMessage className="centered" />
        </If>
        <If condition={!error}>
          <Modal onClose={closeModal} scrollBehavior="inside-wide" width="x-large" >
            <ModalBody>
            <h2 className="text-base font-bold">Select tests to unlink</h2>
            <div className="my-2">
              <Checkbox
                value={allSelected}
                isChecked={allSelected}
                key="all"
                onChange={selectAll}
                label={(
                  <span>
                    All tests
                  </span>
                    )}
              />
              <div className="ml-2">
                {tests.map((test) => {
                  return (
                    <Checkbox
                      value={test.id}
                      label={(
                        <div>
                          {test.title}
                          <span className="ml-2">
                            <TestBadge type={test.type} />
                          </span>
                        </div>
              )}
                      isChecked={selectedTests[test.id]}
                      onChange={checkboxCallback}
                      key={test.id}
                    />
                  );
                })}
              </div>
            </div>
            </ModalBody>
            <ModalFooter>
              <Button
                size="small"
                appearance="primary"
                spacing="compact"
                onClick={() => detachFromTestomatio()}
                isDisabled={disabledButton}
                isLoading={deleteLoading}
              >
                Unlink
              </Button>
            </ModalFooter>
          </Modal>
        </If>
      </ModalTransition>
    </>
  );
});

export default UnlinkTests;
