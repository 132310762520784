import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

// components
import Button from '@atlaskit/button/new';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import Spinner from '@atlaskit/spinner';
import useModel from '../hooks/useModel';
import TestView from './TestView';
import SuiteView from './SuiteView';
import EpicView from './EpicView';
import RunsView from './RunsView';
import PlansView from './PlansView';
import { useStore } from '../store';
import If from './If';

const IssuePanel = observer(() => {
  const { isLoading, noProject } = useModel('project', 'issue');
  const [tab, setTab] = useState(null);
  const [page, setPage] = useState(null);
  const { jira } = useStore();
  const [isEpic, setIsEpic] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!tab) {
      const defaultTab = parseInt(localStorage.getItem('tab_id'), 10) || 0;
      setTab(defaultTab);
    }
    if (!page) {
      const defaultPage = parseInt(localStorage.getItem('page'), 10) || 1;
      setPage(defaultPage);
    }
    if (!jira.jiraId) return;
    jira.getIssue(jira.jiraId)
      .then(r => {
        if (r.fields.issuetype.name === 'Epic') {
          setIsEpic(true);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [tab, page, jira.jiraId]);

  const changePage = useCallback((p) => {
    setPage(p);
    localStorage.setItem('page', p);
  }, []);

  const changeTab = useCallback((id) => {
    setTab(id);
    changePage(1);
  }, [changePage]);

  const setPreference = (tabId) => {
    if (tabId === 3) {
      localStorage.setItem('tab_id', 0);
    } else {
      localStorage.setItem('tab_id', tabId);
    }
    changePage(1);
    setTab(tabId);
  };

  if (isLoading) {
    return <div className="flex justify-center mt-32"><Spinner size="large" /></div>;
  }

  if (!isLoading && noProject) {
    return (
      <div className="flex w-full mt-4 justify-center flex-col items-center">
        <h1 className="text-xl">
          Oops! No project is linked.
        </h1>
        <a
          href="https://docs.testomat.io/integration/jira"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer text-blue-500 mt-4 mb-4"
        >
          Learn how to connect Jira to Testomatio
        </a>
        <Button onClick={() => window.location.reload()}>
          Refresh view
        </Button>
      </div>
    );
  }

  const tabs = [
    { label: 'Tests', content: <TestView tab={changeTab} setPage={changePage} page={page} /> },
    { label: 'Runs', content: <RunsView setPage={changePage} page={page} /> },
    { label: 'Suites', content: <SuiteView setPage={changePage} page={page} /> },
    { label: 'Plans', content: <PlansView setPage={changePage} page={page} /> },
    { label: isEpic ? 'Epic View' : null, content: <If condition={isEpic}><EpicView /></If> },
  ];

  return (
    <If condition={!loading}>
      <Tabs id="controlled" onChange={(ind) => setPreference(ind)} selected={tab} >
        <TabList>
          {tabs.map((tab, index) => (
            <Tab key={index}>{tab.label}</Tab>
          ))}
        </TabList>
        {tabs.map((tab, index) => (
          <TabPanel key={index}>{tab.content}</TabPanel>
        ))}
      </Tabs>
    </If>
  );
});

export default IssuePanel;
