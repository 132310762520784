import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// hooks
import usePortal from '../hooks/usePortal';
import { useStore } from '../store';

// components
import If from './If';

const Toast = observer(() => {
  const store = useStore();
  const { jira } = store;
  const TIMEOUT = 5; // sec
  const [canBeShown, setCanBeShown] = useState(true);
  const Modal = usePortal();

  const getBgColor = () => {
    if (!jira.toast) return '';
    const isDark = jira.theme === 'dark';
    if (jira.toast.type === 'error') return isDark ? 'dark-errorNotify' : 'bg-red-100';
    if (jira.toast.type === 'success') return isDark ? 'dark-successNotify' : 'bg-green-100';

    return isDark ? 'dark-infoNotify' : 'bg-blue-100';
  };

  const getTextColor = () => {
    if (!jira.toast) return '';
    const isDark = jira.theme === 'dark';
    if (jira.toast.type === 'error') return isDark ? 'dark-rose' : 'text-red-700';
    if (jira.toast.type === 'success') return isDark ? 'text-green-300' : 'text-green-700';

    return isDark ? 'text-blue-400' : 'text-blue-700';
  };

  const onClose = () => setCanBeShown(false);

  useEffect(() => {
    if (!jira.toast) {
      setCanBeShown(false);
      return;
    }
    setCanBeShown(true);
  }, [jira.toast]);

  useEffect(() => {
    let timer;
    if (jira.toast) {
      timer = setTimeout(() => setCanBeShown(false), TIMEOUT * 1000);
    } else return;

    return () => {
      clearTimeout(timer);
    };
  }, [jira.toast]);

  if (!jira.toast) {
    return <div />;
  }

  /* eslint-disable */
  return canBeShown ? (
    <Modal>
      <div
        className={`rounded-md ${getBgColor()} p-4 fixed top-0 mb-2`}
        style={{
          zIndex: 1000000,
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
        }}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <If condition={jira.toast.type === "error"}>
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </If>
            <If condition={jira.toast.type === "success"}>
              <svg
                className="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </If>
          </div>
          <div className="ml-3">
            <p className={`text-sm font-medium ${getTextColor()}`}>
              {jira.toast.message}
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex ${getBgColor()} rounded-md p-1.5 ${getTextColor()} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600`}
                onClick={onClose}
              >
                <span className="sr-only">Dismiss</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {jira.toast.link && (
            <div className="ml-3">
              <a
                className={`text-xs font-medium text-blue-800`}
                href={jira.toast.link}
                target="_blank"
              >
                {jira.toast.link}
              </a>
            </div>
          )}        
      </div>
    </Modal>
  ) : null;
});

export default Toast;
